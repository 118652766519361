import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

export default function Footer() {
  return (
    <footer className="bg-gray-50 py-12 border-t">
      <div className="w-11/12  mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-28 gap-8 mb-8">
          {/* Logo and Description */}
          <div className="space-y-4">
            <Link to="/" className="block">
              <img
                src={require("../assets/navlogo.svg").default}
                alt="Design Elementary"
                className="w-64"
              />
            </Link>
            <p className="text-gray-600 pr-4">
              Transforming spaces into extraordinary experiences through innovative design solutions.
            </p>
          </div>

          {/* Quick Links */}
          <div className="grid grid-cols-2 gap-8">
            <div className="space-y-3">
              <h3 className="font-bold text-lg mb-4">Company</h3>
              <div className="flex flex-col space-y-2">
                <Link className="text-gray-600 hover:text-black transition-colors" to="/about">About Us</Link>
                <Link className="text-gray-600 hover:text-black transition-colors" to="/career">Career</Link>
                <Link className="text-gray-600 hover:text-black transition-colors" to="/cities">Execution Cities</Link>
              </div>
            </div>
            <div className="space-y-3">
              <h3 className="font-bold text-lg mb-4">Support</h3>
              <div className="flex flex-col space-y-2">
                <Link className="text-gray-600 hover:text-black transition-colors" to="/contact">Contact Us</Link>
                <Link className="text-gray-600 hover:text-black transition-colors" to="/policies">Policies</Link>
                <Link className="text-gray-600 hover:text-black transition-colors" to="/terms-and-conditions">Terms & Conditions</Link>
              </div>
            </div>
          </div>

          {/* Social Media & Contact */}
          <div className="space-y-4">
            <h3 className="font-bold text-lg">Connect With Us</h3>
            <div className="flex space-x-4">
              <a href="https://www.facebook.com/DesignElementary/" className="text-gray-600 hover:text-black text-2xl"><FaFacebook /></a>
              <a href="https://www.instagram.com/designelementary_global/" className="text-gray-600 hover:text-black text-2xl"><FaInstagram /></a>
              <a href="https://in.linkedin.com/company/design-elementary" className="text-gray-600 hover:text-black text-2xl"><FaLinkedin /></a>
            </div>
            <div className="space-y-2">
              <p className="text-gray-600">Email: care@designelementary.com</p>
            </div>
          </div>
        </div>
        <div className="text-center text-gray-600 text-sm">
          &copy; {new Date().getFullYear()} Design Elementary. All rights reserved.
        </div>
      </div>
    </footer>
  );
}
