import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { IoIosArrowDown, IoIosMenu, IoIosClose, IoIosArrowForward } from "react-icons/io";
import Dropdown from "./Dropdown";
import { RxAvatar } from "react-icons/rx";
import { AiOutlineShoppingCart, AiOutlineProject, AiOutlineDollarCircle, AiOutlineCalculator } from "react-icons/ai";

const shimmerAnimation = `
@keyframes shimmer {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}`;

export default function Header() {
  const { user } = React.useContext(AppContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [offeringsOpen, setOfferingsOpen] = useState(false); // new state for offerings collapse
  const [architectOpen, setArchitectOpen] = useState(false);
  const [designIdeasOpen, setDesignIdeasOpen] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const location = useLocation();
  const isHome = location.pathname === "/";

  const navLinks = {
    designAi: "/user/profile/design-ai",
    designIdeas: "/blogs",
    shopMaterials: "https://b2c.designelementary.in/#/raw-materials",
    pmc: "https://designelementary.in/turnkey-construction",
    finance: "https://basichomeloan.designelementary.in/",
    costCalculator: "/cost-calculator",
    cities: '/cities',
  };

  const offerings = [
    {
      name: "Interior",
      link: "/interior"
    },
    {
      name: "Turnkey Construction",
      link: "/turnkey-construction"
    },
    {
      name: "Site Supervision & Quality Audit",
      link: "/site-supervision-and-quality-audit"
    }
  ];

  const architect = [
    {
      name: ' Structure',
      link: '/architecture'
    },
    {
      name: "Layout",
      link: "/design/floorplan"
    },
    {
      name: "MEP",
      link: "/"
    },
    {
      name: "Walkthrough",
      link: "/"
    },
    {
      name: "Plan Approval",
      link: "/"
    },
  ]

  const designIdeas = [

    {
      name: "Exterior",
      link: "/design/exterior"
    },
    {
      name: "Landscape",
      link: "/design/landscape"
    },
    {
      name: "#D Elevation",
      link: "/"
    },
  ]

  React.useEffect(() => { }, [user]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleOfferings = () => {
    setOfferingsOpen(!offeringsOpen);
  };

  const toggleArchitect = () => {
    setArchitectOpen(!architectOpen);
  };

  const toggleDesignIdeas = () => {
    setDesignIdeasOpen(!designIdeasOpen);
  };

  // Add scroll event listener
  React.useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsScrollingDown(currentScrollY > lastScrollY);
      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <style>{shimmerAnimation}</style>
      <div className=" md:w-full md:shadow-md mx-auto fixed w-full z-[500000000] group">
        <div className="flex px-4 item justify-between md:px-20 w-full md:shadow-md relative bg-white z-[100] items-center py-3">
          <Link to="/" className="">
            <img
              src={require("../assets/navlogo.svg").default}
              alt="Design Elementary"
              className="md:w-[220px] w-[40vw]"
            />
          </Link>
       
          {/* Hamburger Icon */}
          <div className="md:hidden flex  gap-3 justify-center items-center">
          <Link to={'https://b2c.designelementary.in/#/raw-materials'} onClick={toggleMenu}>
                <button
                  className="relative overflow-hidden px-3 inline-flex items-center gap-2 font-semibold text-sm py-2.5
            border-[#1B454D] rounded-3xl border-r-8
            text-[#1B454D] border shadow-md hover:shadow-lg transform hover:-translate-y-0.5
            transition-all duration-200 ease-in-out focus:outline-none focus:ring-2
            bg-[#1B454D]/10
            before:absolute before:top-0 before:left-0 before:w-full before:h-full
            before:bg-gradient-to-r before:from-transparent before:via-white/50 before:to-transparent
            before:animate-[shimmer_1.5s_ease-in-out_infinite]"
                >
                  <span> Offer 10% Off !</span>
                </button>
              </Link>
            <button onClick={toggleMenu}>
              {!menuOpen && <IoIosMenu size={30} />}
            </button>
          </div>

          {/* Desktop Menu */}
          <div className="hidden ml-5 md:block tooltip px-2 py-2 bg-white text-black tooltip-bottom tooltip-error before:text-white w-1/2 md:w-auto">
            <div className="flex gap-8 text-xl md:text-base">
              <Dropdown label="Architect" items={architect} width="200px" />
              <Dropdown label="Design Ideas" items={designIdeas} width="200px" />
              <Dropdown label="Offerings" items={offerings} width="200px" />
              <Link to={navLinks.cities}>
                <button className="cool-link gap-2 text-[#111928] text-[1.1rem] hover:text-gray-700 transition font-semibold px-4 py-2.5">
                  Cities
                </button>
              </Link>
            </div>
          </div>

          {/* Login, DesignAI and Avatar Links */}
          <div className="hidden md:flex items-center gap-4 tooltip px-2 py-2 bg-white text-green-400 tooltip-bottom tooltip-error before:text-white w-1/2 md:w-auto">

            <Link
              to={'https://b2c.designelementary.in/#/raw-materials'}
              target="_blank"
              rel="noopener noreferrer"
              className="relative overflow-hidden inline-flex items-center gap-2 font-semibold text-base px-4 py-2.5
              border-[#1B454D] rounded-3xl border-r-8
              text-[#1B454D] border shadow-md hover:shadow-lg transform hover:-translate-y-0.5
              transition-all duration-200 ease-in-out focus:outline-none focus:ring-2
              before:absolute before:top-0 before:left-0 before:w-full before:h-full
              before:bg-gradient-to-r before:from-transparent before:via-white/50 before:to-transparent
              before:animate-[shimmer_1.5s_ease-in-out_infinite]"
            >
              <span>Limited Offer 10% Off !</span>
            </Link>

            {!user.Id && (
              <Link
                to="/login" >
                <button
                  className="inline-flex items-center gap-2 font-semibold text-base px-4 py-2.5 rounded-3xl
               bg-[#1B454D] text-white shadow-md hover:shadow-lg transform hover:-translate-y-0.5
                 transition-all duration-200 ease-in-out focus:outline-none focus:ring-2">
                  Login
                </button>
              </Link>
            )}
            {user.Id && (
              <Link to="https://b2c.designelementary.in" target="_blank" rel="noopener noreferrer">
                <div className="relative group cursor-pointer">
                  <RxAvatar
                    size={32}
                    className="rounded-full transition-transform duration-300 ease-in-out transform group-hover:scale-110 group-hover:shadow-lg group-hover:ring-2 group-hover:ring-gray-300"
                  />

                </div>
              </Link>
            )}
          </div>
        </div>

        {/* Mobile Side Menu */}
        <div
          className={`fixed top-0 left-0 h-full w-72 bg-white shadow-lg z-[100000003] 
        transform transition-all duration-300 ease-in-out
        ${menuOpen ? 'translate-x-0' : 'translate-x-[-100%]'}`}
        >
          <div className="h-full  overflow-y-auto">
            <div className="sticky top-0 bg-white z-10 p-4 border-b">
              <button onClick={toggleMenu} className="mb-4">
                <IoIosClose size={30} />
              </button>
            </div>
            <div className="p-4 flex flex-col gap-2">
       
              {!user.Id && (
                <Link to="/login" onClick={toggleMenu}>
                  <button
                    className="inline-flex items-center gap-2 font-semibold text-base px-4 py-2.5 rounded-3xl
                                  bg-[#1B454D] text-white shadow-md hover:shadow-lg transform hover:-translate-y-0.5
                                    transition-all duration-200 ease-in-out focus:outline-none focus:ring-2"
                  >
                    Login
                  </button>
                </Link>
              )}
              {user.Id && (
                <Link to="https://b2c.designelementary.in" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>
                  <div className="w-full px-4 py-2 border-b border-gray-200 hover:bg-gray-100 flex items-center">
                    <RxAvatar size={32} className="rounded-full" />
                    <span className="ml-2">Profile</span>
                  </div>
                </Link>
              )}
              <button onClick={toggleArchitect} className="w-full flex justify-between items-center px-4 py-2 border-b border-gray-200 hover:bg-gray-100">
                <span className="font-semibold">Architect</span>
                {architectOpen ? <IoIosArrowDown size={18} /> : <IoIosArrowForward size={18} />}
              </button>
              {architectOpen && (
                <div className="flex flex-col">
                  {architect.map((item, idx) => (
                    <Link key={idx} to={item.link} onClick={toggleMenu}>
                      <button className="w-full text-left px-6 py-2 hover:bg-gray-100">
                        {item.name}
                      </button>
                    </Link>
                  ))}
                </div>
              )}

              <button onClick={toggleDesignIdeas} className="w-full flex justify-between items-center px-4 py-2 border-b border-gray-200 hover:bg-gray-100">
                <span className="font-semibold">Design Ideas</span>
                {designIdeasOpen ? <IoIosArrowDown size={18} /> : <IoIosArrowForward size={18} />}
              </button>
              {designIdeasOpen && (
                <div className="flex flex-col">
                  {designIdeas.map((item, idx) => (
                    <Link key={idx} to={item.link} onClick={toggleMenu}>
                      <button className="w-full text-left px-6 py-2 hover:bg-gray-100">
                        {item.name}
                      </button>
                    </Link>
                  ))}
                </div>
              )}

              <button onClick={toggleOfferings} className="w-full flex justify-between items-center px-4 py-2 border-b border-gray-200 hover:bg-gray-100">
                <span className="font-semibold">Offerings</span>
                {offeringsOpen ? <IoIosArrowDown size={18} /> : <IoIosArrowForward size={18} />}
              </button>
              {offeringsOpen && (
                <div className="flex flex-col">
                  {offerings.map((item, idx) => (
                    <Link key={idx} to={item.link} onClick={toggleMenu}>
                      <button className="w-full text-left px-6 py-2 hover:bg-gray-100">
                        {item.name}
                      </button>
                    </Link>
                  ))}
                </div>
              )}

              <Link to={navLinks.cities} onClick={toggleMenu}>
                <button className="w-full text-left px-4 py-2 border-b border-gray-200 hover:bg-gray-100">
                  Cities
                </button>
              </Link>
              <Link to={navLinks.designAi} onClick={toggleMenu}>
                <button className="w-full text-left px-4 py-2 border-b border-gray-200 hover:bg-gray-100 flex justify-between items-center">
                  <span className="flex items-center gap-1">
                    <span>
                      <img className=" animate-spin w-6 transition-all" src={require('../assets/sparkling.png')} alt="" />
                    </span>
                    Design AI
                    <span className="animate-pulse inline-block"></span>
                  </span>  </button>
              </Link>
              <Link to={navLinks.shopMaterials} onClick={toggleMenu}>
                <button className="w-full text-left px-4 py-2 border-b border-gray-200 hover:bg-gray-100 flex gap-2 items-center">
                  <AiOutlineShoppingCart size={20} />
                  <span>Shop Materials</span>
                </button>
              </Link>
              <Link to={navLinks.pmc} onClick={toggleMenu}>
                <button className="w-full text-left px-4 py-2 border-b border-gray-200 hover:bg-gray-100 flex gap-2 items-center">
                  <AiOutlineProject size={20} />
                  <span>PMC</span>
                </button>
              </Link>
              <Link to={navLinks.finance} onClick={toggleMenu}>
                <button className="w-full text-left px-4 py-2 border-b border-gray-200 hover:bg-gray-100 flex gap-2 items-center">
                  <AiOutlineDollarCircle size={20} />
                  <span>Finance</span>
                </button>
              </Link>
              <Link to={navLinks.costCalculator} onClick={toggleMenu}>
                <button className="w-full text-left px-4 py-2 border-b border-gray-200 hover:bg-gray-100 flex gap-2 items-center">
                  <AiOutlineCalculator size={20} />
                  <span>Cost Calculator</span>
                </button>
              </Link>
            </div>
          </div>
        </div>

        {/* Add overlay when menu is open */}
        {menuOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-[100000002] transition-opacity duration-300"
            onClick={toggleMenu}
          />
        )}

        <div className={`absolute hidden bg-transparent -bottom-16 z-50 mx-64 h-16 w-[65%] md:flex items-center justify-center transition-all duration-300 ${
          isHome && !isScrollingDown ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-4 pointer-events-none"
        }`}
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='600' height='80' viewBox='0 0 750 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M747.738 9.9791e-06C799.425 9.3134e-06 -52.4647 -1.70705e-05 2.55652 8.07175e-06C25.2818 1.84562e-05 41.0567 8.5296 51.7458 18.5429C68.0398 33.8065 87.25 50 109.576 50H345.489H643.199C664.568 50 683.144 35.3519 698.317 20.3044C709.159 9.55104 725.148 1.027e-05 747.738 9.9791e-06Z' fill='white'/%3E%3C/svg%3E")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="flex items-center justify-center gap-8">
            <Link to={navLinks.designAi}>
              <button className="cool-link text-[#111928] hover:text-gray-700 transition whitespace-nowrap">
                <span className="flex flex-row items-center gap-2">
                  <span className="flex items-center gap-1">
                    <span>
                      <img className=" animate-spin  w-6 transition-all" src={require('../assets/sparkling.png')} alt="" />
                    </span>
                    Design AI
                    <span className="animate-pulse inline-block"></span>
                  </span>
                </span>
              </button>
            </Link>
            <Link to={navLinks.shopMaterials}>
              <button className="cool-link text-[#111928] hover:text-gray-700 transition whitespace-nowrap">
                <span className="flex flex-row items-center gap-2">
                  <AiOutlineShoppingCart size={20} />
                  <span>Shop Materials</span>
                </span>
              </button>
            </Link>
            <Link to={navLinks.pmc}>
              <button className="cool-link text-[#111928] hover:text-gray-700 transition whitespace-nowrap">
                <span className="flex flex-row items-center gap-2">
                  <AiOutlineProject size={20} />
                  <span>PMC</span>
                </span>
              </button>
            </Link>
            <Link to={navLinks.finance}>
              <button className="cool-link text-[#111928] hover:text-gray-700 transition whitespace-nowrap">
                <span className="flex flex-row items-center gap-2">
                  <AiOutlineDollarCircle size={20} />
                  <span>Finance</span>
                </span>
              </button>
            </Link>
            <Link to={navLinks.costCalculator}>
              <button className="cool-link text-[#111928] hover:text-gray-700 transition whitespace-nowrap">
                <span className="flex flex-row items-center gap-2">
                  <AiOutlineCalculator size={20} />
                  <span>Cost Calculator</span>
                </span>
              </button>
            </Link>
          </div>
        </div>

      </div>
    </>
  );
}
