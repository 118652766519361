import React, { useState, useRef, useEffect, useCallback } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";

const Dropdown = ({ label, items, width = "auto" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const closeDropdown = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeDropdown]);

  return (
    <div
      ref={dropdownRef}
      className="relative group"
      onMouseEnter={toggleDropdown}
      onMouseLeave={closeDropdown}
    >
      <button
        className="flex items-center gap-2 text-[#111928] text-lg font-semibold px-4 py-2.5 
        hover:text-gray-700 transition-all duration-200 ease-in-out relative
    "
        aria-expanded={isOpen}
      >
        {label}
        <IoIosArrowDown
          className={`transition-transform duration-300 ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          size={16}
        />
      </button>

      {isOpen && (
        <ul
          className={`absolute left-0 bg-white rounded-lg shadow-lg border border-gray-100
          transform transition-all duration-200 ease-in-out origin-top-left
          ${isOpen ? "scale-100 opacity-100" : "scale-95 opacity-0"}
          py-2 z-50`}
          style={{ width: width }}
        >
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <li className="relative group/item">
                <Link
                  to={item.link}
                  className="block px-6 py-2.5 text-sm text-gray-700 hover:text-[#1B454D]
                  transition-all duration-200 hover:bg-gray-50/80 relative
                  group-hover/item:pl-8"
                >
                  <span className="relative z-10">{item.name}</span>
                  <div className="absolute left-0 top-0 w-1 h-full bg-[#1B454D] transform 
                  scale-y-0 group-hover/item:scale-y-100 transition-transform duration-200"></div>
                </Link>
              </li>
              {index < items.length - 1 && (
                <li className="mx-4 my-1">
                  <div className="border-b border-gray-100"></div>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
