import  { Component } from "react";

import { Crisp } from "crisp-sdk-web";

class CrispChat extends Component {
  componentDidMount () {
    Crisp.configure("2000deb3-ad1d-43f0-840f-19ea2875aaad");
  }

  render () {
    return null;
  }
}
export default CrispChat