import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./pages/Contact";
import AppContextProvider from "./context/AppContext";
import MainLoading from "./components/loader/MainLoading";
import MyDesigns from "./pages/user/profile/my-designs/page";
import MySubscriptions from "./pages/user/profile/my-subscriptions/page";
import Service from "./pages/our-services/Service";
import Services from "./pages/our-services/Services";
import Interior from "./pages/offerings/Interior";
import Architecture from "./pages/offerings/Architecture";
import Turnkey from "./pages/other-services/Turnkey";
import SiteSupervisionAudit from "./pages/other-services/SiteSupervisionAudit";
import Exterior from "./pages/design-ideas/Exterior";
import Landscape from "./pages/design-ideas/Landscape";
import Floorplan from "./pages/design-ideas/Floorplan";
import { BlogProvider } from "./context/BlogContext";
import { ChakraProvider } from '@chakra-ui/react'
import Vendor from "./pages/Vendor";
import B2Bpartner from "./pages/B2Bpartner";
import ArchitectureGallery from "./pages/gallery/ArchitectureGallery";
import InteriorGallery from "./pages/gallery/InteriorGallery";
import CareerPage from "./components/career/careerPage";
import { Toaster } from "react-hot-toast";
import Offers from "./pages/Offers";
import Thanks from "./pages/Thanks";
import NotFound from "./pages/NotFound";
import CostCalculator from "./pages/CostCalculator";
import { AiOutlineAim, AiOutlinePhone } from "react-icons/ai";
import CrispChat from "./components/LiveChat";

const Main = React.lazy(() => import("./pages/Main"));
const About = React.lazy(() => import("./pages/About"));
const TermsConditions = React.lazy(() => import("./pages/TermsConditions"));
const Policies = React.lazy(() => import("./pages/Policies"));
const Blog = React.lazy(() => import("./pages/Blog"));
const Blogs = React.lazy(() => import("./pages/Blogs"));
const Details = React.lazy(() => import("./pages/user/profile/details/page"));
const DesignAIPage = React.lazy(
  () => import("./pages/user/profile/design-ai/page")
);
const SignIn = React.lazy(() => import("./pages/Signin"));
const SignUp = React.lazy(() => import("./pages/Signup"));
const Cities = React.lazy(() => import("./pages/Cities"));
const Support = React.lazy(() => import("./pages/user/profile/support/page"));
const Gallery = React.lazy(() => import("./pages/Gallery"));
const AllSubscriptionsLayout = React.lazy(
  () => import("./pages/subscriptions/layout")
);
const HomeStaging = React.lazy(() => import("./pages/HomeStaging"));

function App() {
  return (
    <ChakraProvider>
      <AppContextProvider>
        <a
          href="https://api.whatsapp.com/send?phone=+917892516337&text=Hello,%20I%20would%20like%20to%20know%20more%20about%20Your%Designs"
          className="fixed top-1/2 right-0 z-[9999] p-2  rounded-md border bg-white  shadow-lg"
        >
          <img
            src={require("./assets/whatsapp.png")}
            alt="whatsapp"
            className="w-10 h-10"
          />
        </a>

        <a
          href="tel:+917892516337"
          className="fixed 
          bottom-1/4 right-0 z-[9999] rotate-90  rounded-md border bg-white p-2 shadow-lg"
        >
          <AiOutlinePhone className="border-black border-2 p-1 rounded-full" size={40} />
        </a>

        <div className="absolute z-[5000000000]">
          <CrispChat />
        </div>

        <BlogProvider>
          <Suspense fallback={<MainLoading />}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/about" element={<About />} />
                <Route path="/login" element={<SignIn />} />
                <Route path="/cities" element={<Cities />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/register" element={<SignUp />} />
                <Route path="/career" element={<CareerPage />} />
                <Route path="/cost-calculator" element={<CostCalculator />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsConditions />}
                />
                <Route path="/policies" element={<Policies />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/blogs/:id" element={<Blog />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/services" element={<Services />} />
                <Route path="/services/:id" element={<Service />} />
                {/* <Route path="/user/profile/details" element={<Details />} /> */}
                <Route
                  path="/user/profile/design-ai"
                  element={<DesignAIPage />}
                />
                <Route path="/user/profile/support" element={<Support />} />
                <Route path="/user/profile/my-designs" element={<MyDesigns />} />
                <Route path="/user/profile/my-subscriptions" element={<MySubscriptions />} />
                {/* <Route path="/user/profile/logout" element={<LogoutPage />} /> */}
                <Route
                  path="/subscriptions"
                  element={<AllSubscriptionsLayout />}
                />
                <Route path="/gallery/architecture" element={<ArchitectureGallery />} />
                <Route path="/gallery/interior" element={<InteriorGallery />} />
                <Route path="/interior" element={<Interior />} />
                <Route path="/architecture" element={<Architecture />} />
                <Route path="/design/exterior" element={<Exterior />} />
                <Route path="/design/landscape" element={<Landscape />} />
                <Route path="/design/floorplan" element={<Floorplan />} />
                <Route path="/turnkey-construction" element={<Turnkey />} />
                <Route path="/site-supervision-and-quality-audit" element={<SiteSupervisionAudit />} />
                <Route path="/vendor" element={<Vendor />} />
                <Route path="/B2Bpartner" element={<B2Bpartner />} />
                <Route path="/home-staging" element={<HomeStaging />} />
                <Route path="/lp/offers" element={<Offers />} />
                <Route path="/lp/thanks" element={<Thanks />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </Suspense>
        </BlogProvider>
      </AppContextProvider>

      <Toaster position="bottom-left" />
    </ChakraProvider>
  );
}

export default App;
